header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 8px 12px;
   /* background-color: rgb(52 46 41); */
   color: #fff;
   width: 100%;
   height: 20vmin;
   /* border: 1px solid #fff; */
   /* border-radius: 10vmin; */

   margin-bottom: 5 px;
}

.logo {
   font-size: calc(20px + 2vmin);
   cursor: pointer;
}

.logo h4 {
   margin: 0;
   font-size: calc(1.1rem + 0.65vw);
}
