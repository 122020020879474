.wrap {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   display: flex;
   background-color: rgba(0, 0, 0, 0.7);
   border-bottom: 2px solid #fff;
   border-radius: 0 0 20px 20px;
   box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
      0 30px 60px -30px rgba(0, 0, 0, 0.3), 0 0 0 #1d239b, 0 0 0 currentColor;
}

.logo {
   display: flex;
   justify-content: center;
   align-items: center;
}

.logoTitle {
   margin-bottom: 10px;
   font-weight: 600;
}

.info {
   width: 100%;
   text-align: center;
   /* background-color: rgba(0, 0, 0, 0.4); */
   color: #fff;
   letter-spacing: 0.08em;
   font-weight: 700;
   font-family: "Dalek Pinpoint Bold";
   font-size: calc(1.1rem + 2vh);
   line-height: 2rem;
   /* margin-top: 2vh; */
   padding: calc(2vh + 10px);
   padding-top: calc(1vh + 10px);
   text-transform: uppercase;
   padding-bottom: 25vmin;
}

/* .buttons {
   margin-top: 3vh;
   display: flex;
   justify-content: space-around;
   max-width: 320px;
   margin-left: auto;
   margin-right: auto;
} */
.subTitle {
   font-size: 1.2rem;
   padding-top: 1vh;
}

.subTitle a {
   font-size: 0.95rem;
   text-transform: none;
   letter-spacing: normal;
   vertical-align: top;
}

.bg-red {
   background-color: #9a8917;
   padding: 1% 2%;
   border-radius: 5px;
   font-size: 0.9rem;
   margin: 10px 0;
}

.logos {
   position: relative;
   padding-top: calc(20vmin + 20px);
}

.logos video {
   width: 20vmin;
   border-radius: 10px;
}

.logos a {
   position: absolute;
}

.pirates {
   right: 0;
   bottom: 0;
}

.ff {
   left: 0;
   bottom: 0;
}

.paper {
   right: 0;
   top: 0;
}

.wadjet {
   left: 0;
   top: 0;
}

.worldcup {
   left: 50%;
   top: 0;
   transform: translateX(-50%);
}

.marketing {
   left: 50%;
   bottom: 0;
   transform: translateX(-50%);
}

.logo a {
   position: inherit !important;
}

.logo video {
   width: 30vmin;
   max-width: calc(256px + 4vmin);
}