* {
   box-sizing: border-box;
}

@font-face {
   font-family: "Dalek Pinpoint Bold";
   font-style: normal;
   font-weight: normal;
   src: local("Dalek Pinpoint Bold"),
      url("../public/DalekPinpointBold.woff") format("woff");
}

body {
   margin: 0;
   font-family: system-ui, -apple-system,
      /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   padding: 0;
   margin: 0;
   font-size: calc(12px + 1vmin);
   font-weight: normal;
   /* background-image: url(../public/bg.jpg); */
   /* background-color: #163432; */
   background-color: #131319;
   background-attachment: fixed;
   color: rgba(224, 224, 224, 1);
   background-image: url(./assets/bg2.jpg);
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}
img {
   vertical-align: middle;
}

.center {
   text-align: center;
}

.react-responsive-modal-modal {
   background-color: #626262;
   color: black;
}
