.wrap {
   margin: 5px 0;
   text-align: center;
}

.label {
   color: #fff;
   font-weight: 600;
   font-size: calc(10px + 2vmin);
}

.value {
   font-weight: 600;
   color: #fff;
   font-size: calc(14px + 2vmin);
   font-family: "Dalek Pinpoint Bold";
   color: transparent;
   background-image: linear-gradient(#827633, #613425);
   -webkit-background-clip: text;
   background-clip: text;
}
