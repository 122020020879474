.wrap {
   padding: 0 5px;
   /* height: 500px; */
}
.s {
   margin: auto;
   box-shadow: 5px 5px 16px rgb(0 0 0 / 54%),
      -5px -5px 9px rgb(255 255 255 / 13%);
   /* border-radius: 3px 3px 0 0; */
   font-family: "Dalek Pinpoint Bold";
   font-size: 14px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #000;
   padding: 1vmin 0;
   box-sizing: content-box;
}
.failed {
   background-color: #ff4236;
}
.active {
   background-color: #f7e66a;
}
.upcoming {
   background-color: #d8bf87;
}
.done {
   background-color: rgb(1, 207, 138);
}

.s1 {
   width: 100%;
   height: 5%;
}

.s2 {
   width: 95%;
   height: 5%;
}

.s3 {
   width: 90%;
   height: 5%;
}

.s4 {
   width: 85%;
   height: 5%;
}

.s5 {
   width: 80.5%;
   height: 4.5%;
}

.s6 {
   width: 76.5%;
   height: 4.5%;
}

.s7 {
   width: 72.5%;
   height: 4.5%;
}
.s8 {
   width: 66.5%;
   height: 4.5%;
}
.s9 {
   width: 62%;
   height: 4.5%;
}
.s10 {
   width: 57.5%;
   height: 4.5%;
}
.s11 {
   width: 53%;
   height: 4.5%;
}
.s12 {
   width: 48.5%;
   height: 4.5%;
}
.s13 {
   width: 44.5%;
   height: 4.5%;
}
.s14 {
   width: 40.5%;
   height: 4.5%;
}
.s15 {
   width: 36.5%;
   height: 4.5%;
}
.s16 {
   width: 33%;
   height: 4.5%;
}
.s17 {
   width: 29.5%;
   height: 4.5%;
}
.s18 {
   width: 25%;
   height: 4.5%;
   font-size: 12px;
}
.s19 {
   width: 21.5%;
   height: 4.5%;
}
.s20 {
   width: 18%;
   padding: 5px;
}
.s21 {
   background-image: url(../../assets/eye.jpg);
   width: 18%;
   height: 10vw;
   background-repeat: no-repeat;
   background-position: bottom;
   background-size: contain;
   margin: auto;
}
.s21.shine {
   background-image: url(../../assets/shine.gif);
   height: 10vmax;
}
.form {
   background-color: #ff4236;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 15px;
}
