.wrap {
   text-align: center;
   background-color: #d2d2af;
}

.content {
   font-size: calc(24px + 2vmin);
   font-family: "Dalek Pinpoint Bold";
   color: transparent;
   background-image: linear-gradient(#746722, #6f2810);
   -webkit-background-clip: text;
   background-clip: text;
   /* text-shadow: 0 0 5px rgb(0 0 0 / 50%); */
   /* padding: 10px 0; */
}
